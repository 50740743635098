var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section3"},[_c('div',{staticClass:"bg-img"}),(_vm.isPC)?_c('div',{staticClass:"parallax-img"},[_c('div',{directives:[{name:"rellax",rawName:"v-rellax",value:({
        // Rellax Options
        // See: https://github.com/dixonandmoe/rellax#features
        speed: -3,
      }),expression:"{\n        // Rellax Options\n        // See: https://github.com/dixonandmoe/rellax#features\n        speed: -3,\n      }"}]},[_c('img',{attrs:{"src":require("./s3/img.jpg"),"alt":"img"}})])]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"parallax-img"},[_c('div',{directives:[{name:"rellax",rawName:"v-rellax",value:({
        // Rellax Options
        // See: https://github.com/dixonandmoe/rellax#features
        speed: -0.75
      }),expression:"{\n        // Rellax Options\n        // See: https://github.com/dixonandmoe/rellax#features\n        speed: -0.75\n      }"}]},[_c('img',{attrs:{"src":require("./s3/img.jpg"),"alt":"img"}})])]):_vm._e(),(_vm.isPC)?_c('img',{staticClass:"title-img",attrs:{"src":require("./s3/title.png"),"alt":((_vm.info.caseName) + "_title")}}):_vm._e(),(_vm.isMobile)?_c('img',{staticClass:"title-img",attrs:{"src":require("./s3/title_m.png"),"alt":((_vm.info.caseName) + "_title")}}):_vm._e(),_c('img',{staticClass:"logo",attrs:{"src":require("./s3/logo.png"),"alt":((_vm.info.
  caseName) + "_logo")}}),_c('h1',{staticClass:"title"},[_vm._v("\n    騎士堡 夢幻樂園\n  ")]),_vm._m(0),_c('div',{staticClass:"name"},[_vm._v("情境示意圖")]),_c('div',{staticClass:"animate-bg"},[_c('img',{staticClass:"bubble3",attrs:{"src":require("./s1/bubb (3).png"),"alt":((_vm.info.caseName) + "_bubble3")}}),_c('img',{staticClass:"bubble4",attrs:{"src":require("./s1/bubb (5).png"),"alt":((_vm.info.caseName) + "_bubble4")}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desc"},[_vm._v("\n    不再煩惱要去哪邊放電了！"),_c('br'),_vm._v("\n    全台最受歡迎的室內樂園，"),_c('br'),_vm._v("\n    把「多元設施」及「情境體驗」搬來，"),_c('br'),_vm._v("\n    打造城市探險，全場免費，親子嗨玩！\n  ")])}]

export { render, staticRenderFns }