import { isMobile } from '../utils/index'
export default {
  address:'桃園市幸福路201號旁',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1807.587435393966!2d121.2989889580804!3d25.02813889599381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjXCsDAxJzQxLjMiTiAxMjHCsDE4JzAwLjMiRQ!5e0!3m2!1szh-TW!2stw!4v1611754642852!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/exyjkmY98H8aH5wm9',
  phone: '',
  fbLink: 'https://www.facebook.com/%E9%81%9B%E6%A8%82KID-103223281731000',
  fbMessage: 'https://m.me/A103223281731000',
  caseName: '遛樂KID',
  indigatorLength: 10,
  email: '',

  houseInfos: [
   /*['投資興建', '美聯開發股份有限公司',],
    ['建築設計', '九騰建築師事務所',],
    ['公設設計', '珩荷空間設計',],
    ['景觀設計', '澳洲PTW建築師事務所',],
    ['基地面積', '1128.34坪',],
    ['營造公司', '昇北營造股份有限公司',],
    ['企劃銷售', '得邦廣告有限公司',],
    ['樓層規劃', '地下6層',],*/
  ],

  gtmCode: ['MXF8KJB'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6LfGUjEaAAAAAFR9F5m6Lg1RfS-D07l64KWmoLcK', // recaptcha v3
  recaptcha_user_token: '6Lep-78UAAAAAIDMeDQOBwK-OK3yNooejpZeNCsS',
  order: {
    title: '報名遊玩',
    subTitle: ''
  }
}
